<template>
  <b-container>
    <b-row
      class="justify-content-center pt-2"
      style="background-color: #f6f7fa;"
    >
      <b-col
        id="contentPrintA5" ref="contentPrintA5"
        cols="5"
        md="10"
      >
        <b-container class="h-100 bg-wave">
          <div class="pt-5 px-3 pb-3">
            <b-row class="mx-0">
              <b-col
                class="mb-1"
                cols="5"
              >
                <h6 class="size16 fw-bold-800 text-dark mb-1">
                  {{ userData.name_in_invoice }}
                </h6>
                <h6 class="size12 fw-bold-600 text-dark text-darken-5 mb-0">
                  {{ userData.address }},<br>
                  / {{ userData.postal_code }}
                </h6>
              </b-col>
              <b-col
                cols="5"
                offset="1"
                class="pl-5"
              >
                <div class="" v-if="$route.query.type !== 'other'">
                  <h6
                    class="size14 fw-bold-500 text-dark mb-0 text-right"
                    style="line-height: 20px;"
                  >
                    {{ result.date || '-' }} <br>
                    Kepada Yth : <br>
                    {{ supplier.name || '-' }}
                  </h6>
                  <h6 class="size14 fw-bold-500 text-dark mb-0 text-right" v-html="supplier.address"></h6>
                </div>
              </b-col>
              <b-col cols="11">
                <hr>
              </b-col>
            </b-row>
            <b-row class="mx-0">
              <b-col cols="11">
                <div v-if="$route.query.type == 'supplier'">
                  <TableSupplier :items="items" />
                </div>
                <div v-if="$route.query.type == 'order'">
                  <TableOrder :items="items" />
                </div>
                <div v-if="$route.query.type == 'other'">
                  <TableOther :fields="fields" :items="items" />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'
import TableSupplier from '@/components/Payout/PaymentReport/TableSupplier.vue'
import TableOther from '@/components/Payout/PaymentReport/TableOther.vue'
import TableOrder from '@/components/Payout/PaymentReport/TableOrder.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    TableSupplier,
    TableOther,
    TableOrder,
  },
  props: {
    result: {
      type: Object,
    },
  },
  data() {
    return {
      fields: [],
      items: [],
      supplier: {},
      userData: JSON.parse(localStorage.getItem('userData')).merchant,
    }
  },
  watch: {
    result: {
      handler(value) {
        if (this.$route.query.type === 'supplier') {
          this.supplier = value.supplier
          this.fields = ['po_so_id', 'delivery_numbers', 'invoice_number', 'factur_number', 'amount', 'metode_bayar']
          for (let index = 0; index < value.payments.length; index++) {
            const element = value.payments[index]
            this.items.push({
              po_so_id: element.po_so_id,
              delivery_numbers: element.delivery_numbers,
              invoice_number: element.invoice_number,
              factur_number: element.factur_number,
              amount: element.amount,
              vouchers: element.vouchers,
            })
          }
        }
        if (this.$route.query.type === 'order') {
          this.supplier = value.customer
          this.fields = ['po_so_id', 'delivery_numbers', 'invoice_number', 'factur_number', 'amount', 'metode_bayar']
          for (let index = 0; index < value.payments.length; index++) {
            const element = value.payments[index]
            this.items.push({
              po_so_id: element.po_so_id,
              delivery_numbers: element.delivery_numbers,
              invoice_number: element.invoice_number,
              factur_number: element.factur_number,
              amount: element.amount,
            })
          }
        }
        if (this.$route.query.type === 'other') {
          this.fields = [
            {
              label: 'Tanggal',
              key: 'date',
            },
            {
              label: 'Tipe',
              key: 'tipe_pembayaran',
            },
            {
              label: 'Jenis',
              key: 'jenis_pembayaran',
            },
            {
              label: 'Detail',
              key: 'detail',
            },
            {
              label: 'Penerima',
              key: 'receiver',
            },
            {
              label: 'No. Invoice/SPT',
              key: 'invoice_number_spt',
            },
            {
              label: 'Nilai',
              key: 'amount',
            },
            {
              label: 'Metode Pembayaran',
              key: 'payment_method',
            },
            // {
            //   label: 'Bukti Bayaran',
            //   key: 'file_attachment',
            // },
          ]
          this.supplier = value
          this.items = [{
            date: value.date,
            tipe_pembayaran: value.tipe_pembayaran,
            jenis_pembayaran: value.jenis_pembayaran,
            detail: value.detail,
            receiver: value.receiver,
            invoice_number_spt: value.invoice_number_spt,
            amount: value.amount,
            payment_method: value.payment_method,
            // file_attachment: value.file_attachment,
          }]
        }
      },
      deep: true,
    },
  },
  mounted() {
    // console.log(JSON.parse(localStorage.getItem('userData')))
  },
}
</script>

<style lang="scss" scoped>
.bg-wave {
  background-color: #FFFFFF;
  border-radius: 24px;
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  background-color: transparent;
  border-bottom: none;
}

.table {
  thead {
    tr {
      th {
        border-bottom: 1px solid #ebe9f1;
        background-color: transparent;
        border-top: none;
        padding: .5rem 0;
        color: #7A7F94;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      td {
        border-top: none;
        color: $dark;
        font-size: 12px;
        font-weight: 600;
        padding: 1.5rem 0;
        border-bottom: 1px solid #ebe9f1;

        &:nth-child(2) {
          width: 12rem;
        }
      }
    }
  }
}
</style>
