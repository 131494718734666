<template>
  <b-table
    :items="items"
  >
    <template #head(po_so_id)="">
      <span>No. PO</span>
    </template>
    <template #head(delivery_numbers)="">
      <span>No. Surat Jalan</span>
    </template>
    <template #head(invoice_number)="">
      <span>No. Invoice Pemasok</span>
    </template>
    <template #head(factur_number)="">
      <span>No. Faktur Pajak Pemasok</span>
    </template>
    <template #head(amount)="">
      <span>Jumlah Pembayaran</span>
    </template>

    <template #cell(po_so_id)="data">
      <span>
        {{ data.item.po_so_id || '-' }}
      </span>
    </template>
    <template #cell(delivery_numbers)="data">
      <span style="width: 12rem;white-space: break-spaces">
        {{ data.item.delivery_numbers.map(e => e.delivery_number_note).join(', ') || '-' }}
      </span>
    </template>
    <template #cell(invoice_number)="data">
      <span>
        {{ data.item.invoice_number || '-' }}
      </span>
    </template>
    <template #cell(factur_number)="data">
      <span>
        {{ data.item.factur_number || '-' }}
      </span>
    </template>
    <template #cell(amount)="data">
      <span>
        {{ data.item.amount | formatAmount }}
      </span>
    </template>
    <!-- <template #cell(metode_bayar)="">
      <h6 class="text-dark size12 fw-bold-600">Giro</h6>
      <h6 class="text-dark size12 fw-bold-600">Nomor Giro: 5745262659</h6>
      <h6 class="text-dark size12 fw-bold-600 mb-0">Nama: 5745262659</h6>
    </template> -->
  </b-table>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    items: {
      type: '',
    },
  },
}
</script>
