<template>
  <b-container
    fluid
    style="
      position: absolute;
      bottom: 0;
      height: calc(100vh - 223px) !important;
    "
  >
    <b-row class="justify-content-center" style="background-color: #f6f7fa">
      <b-col cols="12" lg="5">
        <div style="position: absolute; left: -1000rem">
          <b-container id="thermal-print" class="h-100 bg-wave">
            <div
              class="pt-md-3 px-lg-3 px-2 pb-3"
              style="letter-spacing: 0.3px"
            >
              <!-- Logo -->
              <b-img
                v-if="
                  (result.branch && result.branch.photo_url.includes('png')) ||
                  result.branch.photo_url.includes('jpg')
                "
                :src="result.branch.photo_url"
                alt="logo"
                class="custom-img"
                style="margin-left: 145px; width: 100px; height: auto"
              />

              <!-- <h6 class="size18 fw-bold-500 text-black mb-0 mt-2 title-struck">
                Struk Penjualan
              </h6> -->

              <!-- Detail -->
              <b-row class="mx-0 mt-2">
                <b-col style="padding: 0" lg="12">
                  <h6
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                    v-if="result.branch"
                  >
                    {{ result.branch.name || "-" }}
                  </h6>
                  <h6
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                    v-if="result.branch"
                  >
                    {{ result.branch.address || "-" }}
                  </h6>
                  <h6
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                    v-if="result.branch"
                  >
                    Tlp: {{ result.branch.phone }}
                  </h6>
                  <h6 class="size10 fw-bold-700 text-black mb-1 details--font">
                    No. Invoice: {{ result.invoice_number || "-" }}
                  </h6>
                  <h6
                    v-if="result.po_so_number"
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                  >
                    No. PO SO: {{ result.po_so_number || "-" }}
                  </h6>
                  <h6
                    v-if="deliveryNumbers && deliveryNumbers.length"
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                  >
                    No. Surat Jalan: {{ deliveryNumbers.join(", ") || "-" }}
                  </h6>
                  <h6 class="size10 fw-bold-700 text-black mb-1 details--font">
                    Tanggal: {{ result.date || "-" }}
                  </h6>
                </b-col>
                <b-col style="padding: 0" md="12" lg="12">
                  <h6 class="size10 fw-bold-700 text-black mb-1 details--font">
                    Sales: {{ (result.seller && result.seller.name) || "-" }}
                  </h6>
                  <h6 class="size10 fw-bold-700 text-black mb-1 details--font">
                    Kasir:
                    {{ (result.operator && result.operator.name) || "-" }}
                  </h6>
                  <h6
                    v-if="result.customer"
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                  >
                    Pelanggan: {{ result.customer.name }}
                  </h6>
                  <!-- <h6
                    v-if="result.customer"
                    class="size10 fw-bold-700 text-black mb-1 details--font"
                  >
                    Pelanggan: {{ result.customer.name }}
                  </h6> -->
                  <!-- <div
                    v-if="result.receiver_name"
                    class="d-flex"
                  >
                    <h6 class="size10 fw-bold-600 text-black">
                      Penerima:
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black ml-25">
                       {{ result.receiver_name || '-' }}
                    </h6>
                  </div>
                  <div
                    v-if="result.receiver_address"
                    class="d-flex"
                  >
                    <h6 class="size10 fw-bold-600 text-white" style="opacity: 0.5; text-fill-color: transparent;">
                      Penerima:
                    </h6>
                    <h6
                      class="size10 fw-bold-500 text-black ml-25 text-break w-25"
                      style="margin-left: 0.2rem;"
                      v-html="sanitizeHTML(result.receiver_address)"
                    />
                  </div> -->
                  <!-- <div
                    v-if="result.receiver_phone_number"
                    class="d-flex"
                  >
                    <h6 class="size10 fw-bold-500 text-black">
                      Telp:&nbsp;
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black ml-25">
                      {{ result.receiver_phone_number || '-' }}
                    </h6>
                  </div> -->
                  <div
                    v-if="result.delivery_pickup === 'delivery'"
                    class="d-flex"
                  >
                    <h6
                      class="size10 fw-bold-700 text-black text-capitalize details--font"
                    >
                      Pengiriman: {{ result.delivery_pickup }}
                    </h6>
                  </div>
                </b-col>
                <b-col style="padding: 0" lg="12">
                  <hr class="mb-25" style="border-top: 1px solid #000" />
                </b-col>
              </b-row>

              <!-- List Produk -->
              <table style="width: 100%">
                <tbody>
                  <tr v-for="(item, index) in result.items" :key="`0-${index}`">
                    <td class="padding--print">
                      <h6
                        class="size10 fw-bold-700 text-black px-0 details--font"
                      >
                        {{ item.name }}
                      </h6>
                      <table style="width: 100%">
                        <tbody>
                          <tr>
                            <td style="width: 20%">
                              <h6
                                class="size10 fw-bold-700 text-black px-0 details--font"
                              >
                                {{ item.qty.toString().replace(".", ",") }}
                                {{ item.unit }}
                              </h6>
                            </td>
                            <td style="text-align: right; width: 25%">
                              <h6
                                class="size10 fw-bold-700 text-black px-0 details--font"
                              >
                                {{ item.sub_price | formatInvoice }}
                              </h6>
                            </td>
                            <td style="text-align: right; width: 25%">
                              <h6
                                class="size10 fw-bold-700 text-black px-0 details--font"
                              >
                                {{ item.total | formatInvoice }}
                              </h6>
                            </td>
                            <td style="width: 13%"></td>
                          </tr>
                          <tr v-if="item.discount_per_item > 0">
                            <td style="width: 20%">
                              <h6
                                class="size10 fw-bold-500 text-black px-0 mr-25"
                              >
                                Diskon
                              </h6>
                            </td>
                            <td style="text-align: right; width: 25%">
                              <h6
                                class="size10 fw-bold-700 text-black px-0 details--font"
                              >
                                -{{ item.discount_per_item | formatInvoice }}
                              </h6>
                            </td>
                            <td style="text-align: right; width: 25%">
                              <h6
                                class="size10 fw-bold-700 text-black px-0 details--font"
                              >
                                -{{ item.discount | formatInvoice }}
                              </h6>
                            </td>
                            <td style="width: 13%"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr class="my-0" style="border-top: 1px solid #000" />

              <!-- Subtotal -->
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td class="py-25">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                        style="width: 100px"
                      >
                        Subtotal
                      </h6>
                    </td>
                    <td class="py-25 text-right">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        {{ result.subtotal | formatAmount }}
                      </h6>
                    </td>
                  </tr>
                  <tr v-if="result.discount_value > 0">
                    <td class="py-25">
                      <h6 class="text-black size10 mb-0 details--font">
                        Diskon Tambahan
                      </h6>
                    </td>
                    <td class="py-25 text-right">
                      <h6
                        v-if="result.discount_type == 'fixed'"
                        class="size10 text-black mb-0 text-right details--font"
                      >
                        - {{ result.discount_value | formatAmount }}
                      </h6>
                      <h6
                        v-if="result.discount_type == 'percent'"
                        class="size10 text-black mb-0 text-right details--font"
                      >
                        - {{ result.discount_value }}%
                      </h6>
                    </td>
                    <td style="width: 15%"></td>
                  </tr>
                  <!-- Biaya Tambahan -->
                  <tr v-if="result.additional_fee_total > 0">
                    <td class="pb-25">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        Total Biaya Tambahan
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        {{ result.additional_fee_total | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <tr
                    v-if="
                      result &&
                      result.additional_fees &&
                      result.additional_fees.length
                    "
                    v-for="(item, index) in result.additional_fees"
                    :key="`additional-cost-${index}`"
                  >
                    <td class="pb-25 pl-5">
                      <h6
                        class="text-black fw-bold-700 size10 mb-0 text-capitalize details--font"
                      >
                        {{ item.additional_fee_note }}
                      </h6>
                    </td>
                    <td class="pb-25 text-right pr-5">
                      <h6
                        class="text-black fw-bold-700 size10 mb-0 details--font"
                      >
                        {{ item.additional_fee_amount | formatAmount }}
                      </h6>
                    </td>
                    <td style="width: 15%"></td>
                  </tr>
                  <!-- Total Diskon -->
                  <tr v-if="result.discount_total > 0">
                    <td class="pb-25">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        Total Diskon
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <!-- Split Payment -->
                  <tr
                    v-if="result.payments && result.payments.length > 1"
                    style="border-top: 1px solid #000"
                  >
                    <td class="pb-25" style="width: 50%">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        Pembayaran Terbagi
                      </h6>
                    </td>
                  </tr>
                  <tr
                    v-for="(payment, indexPaymentPrint) in result.payments"
                    :key="indexPaymentPrint"
                    :style="{
                      'border-bottom':
                        result.payments &&
                        result.payments.length > 1 &&
                        indexPaymentPrint === result.payments.length - 1
                          ? '1px solid #000'
                          : 'none',
                    }"
                  >
                    <td class="pb-25">
                      <h6
                        class="text-black size10 mb-0 text-capitalize fw-bold-700 details--font"
                      >
                        {{ paymentMethods(payment.payment_method) }}
                        {{
                          payment.payment_method == "transfer"
                            ? " ke " + payment.bank_account_payment.bank.name
                            : ""
                        }}
                      </h6>
                    </td>
                    <td colspan="1" class="text-right pb-25">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        {{ parseFloat(payment.amount) | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <tr v-if="result.payments && result.payments.length > 1">
                    <td class="pb-25" style="width: 50%">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        Total Pembayaran
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 details--font"
                      >
                        {{ sumSplitPayment(result.payments) | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <!-- Grand Total -->
                  <tr>
                    <td class="pb-25" style="width: 50%">
                      <h6
                        class="text-black size10 mb-0 fw-bold-700 grand--total"
                      >
                        Grand Total
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6
                        class="text-black size12 mb-0 fw-bold-700 grand--total"
                      >
                        {{ result.total | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <!-- Piutang -->
                  <tr
                    v-if="
                      result.payment_method !== 'piutang' &&
                      result.piutang &&
                      result.piutang.amount > 0
                    "
                  >
                    <td class="pb-25">
                      <h6 class="text-black size10 mb-0 text-capitalize">
                        Piutang
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6 class="text-black size10 mb-0">
                        {{
                          result.piutang && result.piutang.amount | formatAmount
                        }}
                      </h6>
                    </td>
                    <td style="width: 15%"></td>
                  </tr>
                  <tr v-if="result.payment_method !== 'piutang'">
                    <td class="pb-25" v-if="result.payment_method === 'wallet'">
                      <h6 class="text-black size10 mb-0">Sisa Deposit</h6>
                    </td>
                    <td
                      class="pb-25 text-right"
                      v-if="result.payment_method === 'wallet'"
                    >
                      <h6 class="text-black size10 mb-0">
                        {{
                          result.payment_customer &&
                          result.payment_customer.wallet_balance | formatAmount
                        }}
                      </h6>
                    </td>
                    <td
                      v-if="
                        result.cash_change > 0 ||
                        (result.returs_id && result.returs_id.length)
                      "
                    >
                      <h6 class="text-black size10 mb-0 details--font">
                        Kembali
                      </h6>
                    </td>
                    <td
                      v-if="
                        result.cash_change > 0 ||
                        (result.returs_id && result.returs_id.length)
                      "
                    >
                      <h6
                        class="text-black size10 mb-0 text-right fw-bold-700 details--font"
                      >
                        {{ result.cash_change | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                  <tr v-if="result.discount_total > 0">
                    <td class="pb-25">
                      <h6 class="text-black size10 mb-0 details--font">
                        Anda Hemat
                      </h6>
                    </td>
                    <td class="pb-25 text-right">
                      <h6 class="text-black size10 mb-0 details--font">
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </td>
                    <td class="pb-25" style="width: 15%"></td>
                  </tr>
                </tbody>
              </table>

              <!-- TTD Penerima & Retur -->
              <b-row class="mx-0 mt-lg-0 mt-3">
                <b-col class="px-0" lg="12">
                  <!-- <div
                    class="d-flex justify-content-between mb-lg-2 mb-5 my-25"
                  >
                    <h6 class="size10 fw-bold-500 text-black mt-2">
                      TTD Penerima
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black" />
                  </div> -->
                  <div class="d-flex justify-content-between mb-lg-2 my-25">
                    <h6
                      class="size10 fw-bold-700 text-black mt-3 mt-lg-2 details--font"
                    >
                      Note:
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black" />
                  </div>
                  <div
                    class="d-flex justify-content-between"
                    style="letter-spacing: 0px !important"
                  >
                    <h6
                      class="size10 fw-bold-700 text-black mb-lg-2 font-italic details--font"
                      v-if="result.display_vat === 1"
                    >
                      *Harga barang sudah termasuk PPn 11%
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black" />
                  </div>
                  <div
                    class="d-flex justify-content-between"
                    style="letter-spacing: 0px !important"
                  >
                    <h6
                      class="size10 fw-bold-700 text-black mb-lg-2 font-italic details--font"
                      style="width: 42%"
                    >
                      {{ result.invoice_notes }}
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black" />
                  </div>
                </b-col>
                <b-col class="px-0" lg="12">
                  <h6
                    v-if="
                      result.returs_id &&
                      result.returs_id &&
                      result.returs_id.length > 0
                    "
                    class="size10 fw-bold-500 text-black"
                  >
                    {{ result.returs_id.join(", ") }}
                  </h6>
                </b-col>
                <b-col class="px-0" lg="12">
                  <h6
                    v-if="result.deleted_by"
                    class="size10 fw-bold-700 text-danger"
                  >
                    Dibatalkan oleh : {{ result.deleted_by.name }} /
                    {{ result.deleted_at }}
                  </h6>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </div>

        <!-- Cetak Thermal Mobile -->
        <b-container class="h-100 bg-wave">
          <div
            id="contentPrint"
            ref="contentPrint"
            class="pt-md-3 px-lg-3 px-2 pb-3"
            style="letter-spacing: 0.3px"
          >
            <!-- Logo -->
            <b-img
              v-if="
                (result.branch && result.branch.photo_url.includes('png')) ||
                result.branch.photo_url.includes('jpg')
              "
              :src="result.branch.photo_url"
              alt="logo"
              class="custom-img"
              style="margin-left: 145px; width: 100px; height: auto"
            />

            <!-- <h6 class="size18 fw-bold-500 text-black mb-0 mt-2">
              Struk Penjualan
            </h6> -->

            <!-- Detail -->
            <b-row class="mt-2">
              <b-col lg="12">
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  {{ result.branch.name || "-" }}
                </h6>
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  {{ result.branch.address || "-" }}
                </h6>
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  Tlp: {{ result.branch.phone }}
                </h6>
                <h6 class="size10 fw-bold-500 text-black mb-1">
                  No. Invoice: {{ result.invoice_number || "-" }}
                </h6>
                <h6
                  v-if="result.po_so_number"
                  class="size10 fw-bold-500 text-black mb-1"
                >
                  No. PO SO: {{ result.po_so_number || "-" }}
                </h6>
                <h6
                  v-if="deliveryNumbers && deliveryNumbers.length"
                  class="size10 fw-bold-500 text-black mb-1"
                >
                  No. Surat Jalan: {{ deliveryNumbers.join(", ") || "-" }}
                </h6>
                <h6 class="size10 fw-bold-500 text-black mb-1">
                  Tanggal: {{ result.date || "-" }}
                </h6>
              </b-col>
              <b-col md="12" lg="12">
                <h6 class="size10 fw-bold-500 text-black mb-1">
                  Sales: {{ (result.seller && result.seller.name) || "-" }}
                </h6>
                <h6 class="size10 fw-bold-500 text-black mb-1">
                  Kasir: {{ (result.operator && result.operator.name) || "-" }}
                </h6>
                <h6
                  v-if="result.customer"
                  class="size10 fw-bold-500 text-black mb-1"
                >
                  Pelanggan: {{ result.customer.name }}
                </h6>
                <!-- <h6
                  v-if="result.customer"
                  class="size10 fw-bold-500 text-black mb-1"
                >
                  Pelanggan: {{ result.customer.name }}
                </h6> -->
                <!-- <div
                  v-if="result.receiver_name"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-600 text-black">
                    Penerima :
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black ml-25">
                    {{ result.receiver_name || '-' }}
                  </h6>
                </div>
                <div
                  v-if="result.receiver_address"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-600 text-white" style="opacity: 0.5; text-fill-color: transparent;">
                    Penerima :
                  </h6>
                  <h6
                    class="size10 fw-bold-500 text-black"
                    style="margin-left: 0.2rem;"
                    v-html="sanitizeHTML(result.receiver_address)"
                  />
                </div> -->
                <!-- <div
                  v-if="result.receiver_phone_number"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-500 text-black">
                    Telp :
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black ml-25">
                    {{ result.receiver_phone_number || '-' }}
                  </h6>
                </div> -->
                <div
                  v-if="result.delivery_pickup === 'delivery'"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-500 text-black text-capitalize">
                    Pengiriman: {{ result.delivery_pickup }}
                  </h6>
                </div>
              </b-col>
              <b-col lg="12">
                <hr class="mb-25" style="border-top: 1px solid #000" />
              </b-col>
            </b-row>

            <!-- List Produk -->
            <b-row>
              <b-col lg="12">
                <div
                  v-for="(item, index) in result.items"
                  :key="index"
                  class="mb-25"
                >
                  <div class="d-flex" style="height: 2rem">
                    <h6 class="size10 fw-bold-500 text-black px-0">
                      {{ item.name }}
                    </h6>
                  </div>
                  <div class="d-flex" style="height: 2rem">
                    <h6 class="size10 fw-bold-500 text-black col-3 px-0">
                      {{ item.qty.toString().replace(".", ",") }}
                      {{ item.unit }}
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black col-3 px-0">
                      {{ item.sub_price | formatInvoice }}
                    </h6>
                    <h6
                      v-if="
                        result.items
                          .map((e) => e.discount_per_item)
                          .some((e) => e > 0)
                      "
                      :style="
                        item.discount_per_item > 0 ? 'opacity: 1' : 'opacity: 0'
                      "
                      class="size10 fw-bold-500 text-black col-3 px-0"
                    >
                      -{{ item.discount_per_item | formatInvoice }}
                    </h6>
                    <h6
                      class="size10 fw-bold-500 text-black px-0 col-3 text-right"
                    >
                      {{ item.total | formatInvoice }}
                    </h6>
                  </div>
                </div>
              </b-col>
              <b-col lg="12">
                <hr class="mt-25" style="border-top: 1px solid #000" />
              </b-col>
            </b-row>

            <!-- Subtotal, Retur, dll -->
            <b-row>
              <b-col lg="12">
                <div
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6
                    class="text-black size10 mb-0 fw-bold-500"
                    style="width: 100px"
                  >
                    Subtotal
                  </h6>
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    {{ result.subtotal | formatAmountAndroid }}
                  </h6>
                </div>
                <!-- Biaya Tambahan -->
                <div
                  v-if="result.additional_fee_total > 0"
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    Total Biaya Tambahan
                  </h6>
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    {{ result.additional_fee_total | formatAmountAndroid }}
                  </h6>
                </div>
                <info-additional-cost :result="result" />

                <!-- Split Payment -->
                <hr
                  v-if="result.payments && result.payments.length > 1"
                  class="mb-25"
                  style="border-top: 1px solid #000"
                />
                <h6
                  class="text-black size10 fw-bold-500"
                  v-if="result.payments && result.payments.length > 1"
                >
                  Pembayaran Terbagi
                </h6>
                <div
                  class="d-flex justify-content-between"
                  v-for="(payment, indexPayment) in result.payments"
                  :key="indexPayment"
                >
                  <h6 class="text-black size10 mb-0 text-capitalize">
                    {{ paymentMethods(payment.payment_method) }}
                    {{
                      payment.payment_method == "transfer"
                        ? " ke " + payment.bank_account_payment.bank.name
                        : ""
                    }}
                  </h6>
                  <h6 class="text-black size10">
                    {{ parseFloat(payment.amount) | formatAmountAndroid }}
                  </h6>
                </div>
                <hr
                  v-if="result.payments && result.payments.length > 1"
                  class="mt-0 mb-25"
                  style="border-top: 1px solid #000"
                />
                <!-- Total Split Payment -->
                <div
                  v-if="result.payments && result.payments.length > 1"
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0">Total Pembayaran</h6>
                  <h6 class="size10 text-black mb-0">
                    {{ sumSplitPayment(result.payments) | formatAmountAndroid }}
                  </h6>
                </div>
                <!-- Diskon -->
                <div
                  v-if="result.discount_value > 0"
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0">Diskon Tambahan</h6>
                  <h6
                    v-if="result.discount_type == 'fixed'"
                    class="size10 text-black mb-0"
                  >
                    - {{ result.discount_value | formatAmountAndroid }}
                  </h6>
                  <h6
                    v-if="result.discount_type == 'percent'"
                    class="size10 text-black mb-0"
                  >
                    - {{ result.discount_value }}%
                  </h6>
                </div>
                <div
                  v-if="result.discount_total > 0"
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    Total Diskon
                  </h6>
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    {{ result.discount_total | formatAmountAndroid }}
                  </h6>
                </div>
                <div
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size12 mb-0 fw-bold-500">
                    Grand Total
                  </h6>
                  <h6 class="text-black size10 mb-0 fw-bold-500">
                    {{ result.total | formatAmountAndroid }}
                  </h6>
                </div>
                <div
                  v-if="
                    result.payment_method !== 'piutang' &&
                    result.piutang &&
                    result.piutang.amount > 0
                  "
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0 text-capitalize">
                    Piutang
                  </h6>
                  <h6 class="text-black size10 mb-0">
                    {{
                      result.piutang &&
                      result.piutang.amount | formatAmountAndroid
                    }}
                  </h6>
                </div>
                <div
                  v-if="result.payment_method !== 'piutang'"
                  class="d-flex justify-content-between"
                  :style="
                    result.payments && result.payments.length > 1
                      ? 'margin-bottom: 0.5rem;'
                      : ''
                  "
                >
                  <h6
                    class="text-black size10 mb-0"
                    v-if="result.payment_method === 'wallet'"
                  >
                    Sisa Deposit
                  </h6>
                  <h6
                    class="text-black size10 mb-0"
                    v-if="result.payment_method === 'wallet'"
                  >
                    {{
                      result.payment_customer &&
                      result.payment_customer.wallet_balance
                        | formatAmountAndroid
                    }}
                  </h6>
                  <div
                    class="d-flex justify-content-between w-100 mb-25"
                    v-if="
                      result.cash_change > 0 ||
                      (result.returs_id && result.returs_id.length)
                    "
                  >
                    <h6 class="text-black size10 mb-0">Kembali</h6>
                    <h6 class="text-black size10 mb-0">
                      {{ result.cash_change | formatAmountAndroid }}
                    </h6>
                  </div>
                </div>
                <div
                  v-if="result.discount_total > 0"
                  class="d-flex justify-content-between"
                  style="margin-bottom: 0.5rem"
                >
                  <h6 class="text-black size10 mb-0">Anda Hemat</h6>
                  <h6 class="text-black size10 mb-0">
                    {{ result.discount_total | formatAmountAndroid }}
                  </h6>
                </div>
              </b-col>
              <b-col lg="12">
                <!-- <div
                    class="d-flex justify-content-between mb-lg-2 mb-5 my-25"
                  >
                    <h6 class="size10 fw-bold-500 text-black mt-2">
                      TTD Penerima
                    </h6>
                    <h6 class="size10 fw-bold-500 text-black" />
                  </div> -->
                <div class="d-flex justify-content-between my-25">
                  <h6 class="size10 fw-bold-500 text-black mt-3 mt-lg-2 mb-0">
                    Note:
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black" />
                </div>
                <div class="d-flex justify-content-between">
                  <h6
                    class="size10 fw-bold-500 text-black mb-0 font-italic"
                    v-if="result.display_vat === 1"
                  >
                    *Harga barang sudah termasuk PPn 11%
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black" />
                </div>
                <div class="d-flex justify-content-between">
                  <h6 class="size10 fw-bold-500 text-black mb-lg-2 font-italic">
                    {{ result.invoice_notes }}
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black" />
                </div>
              </b-col>
              <b-col lg="12">
                <h6
                  v-if="
                    result.returs_id &&
                    result.returs_id &&
                    result.returs_id.length > 0
                  "
                  class="size10 fw-bold-500 text-black"
                >
                  {{ result.returs_id.join(", ") }}
                </h6>
              </b-col>
              <b-col lg="12">
                <h6
                  v-if="result.deleted_by"
                  class="size10 fw-bold-700 text-danger"
                >
                  Dibatalkan oleh : {{ result.deleted_by.name }} /
                  {{ result.deleted_at }}
                </h6>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import moment from "moment";
import InfoAdditionalCost from "./InfoAdditionalCost.vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BImg,
    InfoAdditionalCost,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    merchant: {
      type: Object,
    },
    deliveryNumbers: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    sumSplitPayment(payments) {
      let total = 0;
      for (let index = 0; index < payments.length; index++) {
        const element = payments[index];
        total += parseFloat(element.amount);
      }
      return total;
    },
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, "");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

@media screen and (min-width: 500px) {
  .custom-img {
    margin-left: 0px !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.text-black {
  color: #000;
}
.bg-wave {
  background-image: url("../assets/images/wave-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #ffffff !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}
</style>
